<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Update Form Checklist</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="bold" for="type_id_device">Type Deviice </label>
                  <select v-model="form.type_id_device" type="text" class="form-control" required
                    @change="formValidate.type_id_device = ''" :class="{
                      'is-valid': form.type_id_device != '',
                      'is-invalid':
                        formValidate.type_id_device ||
                        form.type_id_device == '',
                    }">
                    <option value="">-Pilih-</option>
                    <option v-for="device in listDevice" :key="device.id_type" :value="device.id_type">
                      {{ device.nama_type }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label class="bold" for="nama_formchecklist">Nama Form Checklist</label>
                  <input v-model="form.nama_formchecklist" type="text" class="form-control"
                    placeholder="Input Nama Form " required @input="formValidate.nama_formchecklist = ''" :class="{
                      'is-valid': form.nama_formchecklist != '',
                      'is-invalid':
                        formValidate.nama_formchecklist || form.nama_formchecklist == '',
                    }" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="bold" for="type_form">Type Form
                  </label>
                  <div>
                    <div class="radio radio-css radio-inline">
                      <input type="radio" value="REMOTE" v-model="form.type_formchecklist" id="radioA" />
                      <label for="radioA">Remote</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input type="radio" value="ONSITE" v-model="form.type_formchecklist" id="radioE" />
                      <label for="radioE">Onsite</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="padding: 0px 5px;">
            <div class="col-xl-6">
              <div class="panel">
                <div class="panel-heading bg-dark text-light">
                  <h3 class="panel-title bold">List Maintenance</h3>
                </div>
                <div class="panel-body p-0">
                  <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" @on-search="onSearch"
                    :totalRows="totalRecords" :isLoading.sync="isLoading" :search-options="{
                      enabled: true,
                      trigger: 'keyup',
                      placeholder: 'Pencarian',
                    }" :pagination-options="{
                enabled: true,
                perPageDropdownEnabled: true,
                perPageDropdown: [10, 20, 30, 40, 50, 100],
                dropdownAllowAll: false,
                position: 'bottom',
                rowsPerPageLabel: '',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: '',
              }" :rows="rows" :columns="columns">
                    <div slot="emptystate" class="text-center">
                      {{ tableInfo }}
                    </div>
                    <div slot="table-actions">
                      <div style="display: flex">
                        <button type="button" v-on:click="fetchData()" class="btn btn-default mr-2">
                          <i class="fa fa-sync" :class="{ 'fa-spin': this.isLoading }"></i>
                        </button>
                      </div>
                    </div>
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'action'">
                        <div>


                          <button type="button" v-on:click="addData(props.row.originalIndex)"
                            class="btn btn-warning mr-2">
                            <i class="fa fa-angle-double-right"></i>
                          </button>

                        </div>
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="panel">
                <div class="panel-heading bg-dark text-light">
                  <h3 class="panel-title bold">Selected Maintenance</h3>
                </div>
                <div class="panel-body p-0">
                  <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" @on-search="onSearch"
                    :totalRows="totalRecordsSelected" :isLoading.sync="isLoading" :search-options="{
                      enabled: true,
                      trigger: 'keyup',
                      placeholder: 'Pencarian',
                    }" :pagination-options="{
                enabled: true,
                perPageDropdownEnabled: true,
                perPageDropdown: [10, 20, 30, 40, 50, 100],
                dropdownAllowAll: false,
                position: 'bottom',
                rowsPerPageLabel: '',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: '',
              }" :rows="rowsSelected" :columns="columns">
                    <div slot="emptystate" class="text-center">
                      {{ tableInfoSelected }}
                    </div>
                    <div slot="table-actions">
                      <div style="display: flex">
                        <button type="button" v-on:click="fetchDataSelected(props.row.originalIndex)"
                          class="btn btn-default mr-2">
                          <i class="fa fa-sync" :class="{ 'fa-spin': this.isLoadingSelected }"></i>
                        </button>
                      </div>
                    </div>
                    <template slot="table-row" slot-scope="props">

                      <span v-if="props.column.field == 'action'">
                        <div>
                          <button type="button" v-on:click="deleteData(props.row.originalIndex)"
                            class="btn btn-danger mr-2">
                            <i class="fa fa-trash"></i>
                          </button>

                        </div>
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>

          <div class="panel-footer">
            <button class="btn btn-success pull-right" @click="postData" variant="primary" :disabled="loading">
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              Update
            </button>
            <a href="javascript:window.history.go(-1);" class="btn btn-default">Kembali</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import userData from "../../../plugins/userData";
import paginations from "../../../plugins/paginations";

export default {
  mixins: [userData, paginations],
  data() {
    return {
      form: {
        type_id_device: "",
        nama_formchecklist: "",
        type_form: "",
      },
      listDevice: [],
      formValidate: [],
      loading: false,
      columns: [
        {
          label: "Category Maintenance",
          field: "nama_cat_maintenance",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Item Maintenance",
          field: "item_maintenance",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-wrap",
        },
        {
          label: "Standar Maintenance",
          field: "standard_maintenance",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      searchTerm: "",
      dataOrigin: [],
      rowsSelected: [],
      tableInfoSelected: "Loading data",
      isLoadingSelected: false,
      totalRecordsSelected: 0,
      searchTermSelected: "",
    };
  },
  mounted() {
    this.getType();
    const id = this.$route.params.id;
    if (id) {
      this.fetchData(id);
    }
  },
  methods: {
    getType() {
      axios
        .get(this.$url + "/v2/type/")
        .then((response) => {
          this.listDevice = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    calculateData() {

      this.totalRecordsSelected = this.rowsSelected.length;
      // if(this.totalRecordsSelected > 0){
      //           this.rows = this.dataOrigin.filter((el) => {
      //             return this.rowsSelected.some((f) => {
      //               return f.id_maintenance !== el.id_maintenance;
      //             });
      //           });
      //         }else{
      //           this.rows = this.dataOrigin;
      //         }
      this.totalRecords = this.rows.length;

      if (this.totalRecords <= 0) {
        this.tableInfo = "Empty data";
      }
      if (this.totalRecordsSelected <= 0) {
        this.tableInfoSelected = "Empty data";
      }

    },

    addData(i) {
      // console.log(i)

      let checked = this.rowsSelected.filter(x => x.id_maintenance == this.rows[i].id_maintenance);
      console.log(checked)
      if (checked.length > 0) {
        Swal.fire({
          title: 'Alert',
          text: 'Data is already !',
          showCancelButton: false,
          showConfirmButton: true,
        });
        return;
      }


      this.rowsSelected.push(this.rows[i]);
      this.calculateData();

    },

    deleteData(i) {

      console.log(i)
      this.rowsSelected.splice(i, 1);
      // const checked = this.rowsSelected.filter(x => x.id_maintenance !== this.rowsSelected[i].id_maintenance);
      this.calculateData();


    },
    fetchData(id) {
      axios
        .get(this.$url + "/v2/formchecklist/detail/" + id)
        .then((response) => {
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
            // console.log(response.data.data[key], key)
          }
          console.log(response.data.data.detail)
          this.rowsSelected = response.data.data.detail;

        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
      this.isLoading = true;
      axios
        .get(this.$url + "/v2/maintenance/", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
          },
        })
        .then((response) => {

          this.dataOrigin = response.data.data;


          this.rows = response.data.data;
          this.calculateData();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(this.$url + "/v2/formchecklist/update/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
<style>
.box-image {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 150px;
  background-size: cover;
  overflow: hidden;
  border-radius: 50%;
}

.box-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>