var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"bold",attrs:{"for":"type_id_device"}},[_vm._v("Type Deviice ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.type_id_device),expression:"form.type_id_device"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.type_id_device != '',
                    'is-invalid':
                      _vm.formValidate.type_id_device ||
                      _vm.form.type_id_device == '',
                  },attrs:{"type":"text","required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "type_id_device", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.formValidate.type_id_device = ''}]}},[_c('option',{attrs:{"value":""}},[_vm._v("-Pilih-")]),_vm._l((_vm.listDevice),function(device){return _c('option',{key:device.id_type,domProps:{"value":device.id_type}},[_vm._v(" "+_vm._s(device.nama_type)+" ")])})],2)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"bold",attrs:{"for":"nama_formchecklist"}},[_vm._v("Nama Form Checklist")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nama_formchecklist),expression:"form.nama_formchecklist"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.nama_formchecklist != '',
                    'is-invalid':
                      _vm.formValidate.nama_formchecklist || _vm.form.nama_formchecklist == '',
                  },attrs:{"type":"text","placeholder":"Input Nama Form ","required":""},domProps:{"value":(_vm.form.nama_formchecklist)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nama_formchecklist", $event.target.value)},function($event){_vm.formValidate.nama_formchecklist = ''}]}})])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"bold",attrs:{"for":"type_form"}},[_vm._v("Type Form ")]),_c('div',[_c('div',{staticClass:"radio radio-css radio-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.type_formchecklist),expression:"form.type_formchecklist"}],attrs:{"type":"radio","value":"REMOTE","id":"radioA"},domProps:{"checked":_vm._q(_vm.form.type_formchecklist,"REMOTE")},on:{"change":function($event){return _vm.$set(_vm.form, "type_formchecklist", "REMOTE")}}}),_c('label',{attrs:{"for":"radioA"}},[_vm._v("Remote")])]),_c('div',{staticClass:"radio radio-css radio-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.type_formchecklist),expression:"form.type_formchecklist"}],attrs:{"type":"radio","value":"ONSITE","id":"radioE"},domProps:{"checked":_vm._q(_vm.form.type_formchecklist,"ONSITE")},on:{"change":function($event){return _vm.$set(_vm.form, "type_formchecklist", "ONSITE")}}}),_c('label',{attrs:{"for":"radioE"}},[_vm._v("Onsite")])])])])])])]),_c('div',{staticClass:"row",staticStyle:{"padding":"0px 5px"}},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"panel"},[_vm._m(1),_c('div',{staticClass:"panel-body p-0"},[_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"search-options":{
                    enabled: true,
                    trigger: 'keyup',
                    placeholder: 'Pencarian',
                  },"pagination-options":{
              enabled: true,
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            },"rows":_vm.rows,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.onSearch,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('div',[_c('button',{staticClass:"btn btn-warning mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.addData(props.row.originalIndex)}}},[_c('i',{staticClass:"fa fa-angle-double-right"})])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.tableInfo)+" ")]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-default mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.fetchData()}}},[_c('i',{staticClass:"fa fa-sync",class:{ 'fa-spin': this.isLoading }})])])])])],1)])]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"panel"},[_vm._m(2),_c('div',{staticClass:"panel-body p-0"},[_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecordsSelected,"isLoading":_vm.isLoading,"search-options":{
                    enabled: true,
                    trigger: 'keyup',
                    placeholder: 'Pencarian',
                  },"pagination-options":{
              enabled: true,
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            },"rows":_vm.rowsSelected,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.onSearch,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('div',[_c('button',{staticClass:"btn btn-danger mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteData(props.row.originalIndex)}}},[_c('i',{staticClass:"fa fa-trash"})])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.tableInfoSelected)+" ")]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-default mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.fetchDataSelected(_vm.props.row.originalIndex)}}},[_c('i',{staticClass:"fa fa-sync",class:{ 'fa-spin': this.isLoadingSelected }})])])])])],1)])])]),_c('div',{staticClass:"panel-footer"},[_c('button',{staticClass:"btn btn-success pull-right",attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.postData}},[(_vm.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" Update ")]),_c('a',{staticClass:"btn btn-default",attrs:{"href":"javascript:window.history.go(-1);"}},[_vm._v("Kembali")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-dark text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Update Form Checklist")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-dark text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("List Maintenance")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-dark text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Selected Maintenance")])])
}]

export { render, staticRenderFns }